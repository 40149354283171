import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SpinnerService {

    isSpinnerVisible: BehaviorSubject<boolean> = new BehaviorSubject(false);

    setSpinner(value: boolean): void {
        this.isSpinnerVisible.next(value);
    }

}
