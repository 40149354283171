import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class AppFooterComponent{
  public config: PerfectScrollbarConfigInterface = {};
  public userName: String = '';
  version = environment.version;
  constructor() { }
  ngOnInit(): void {
  }

}
