import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleBarComponent } from './title-bar/title-bar.component';
import { DemoMaterialModule } from '../demo-material-module';

const components = [
  TitleBarComponent
]

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    DemoMaterialModule
  ]
})
export class ComponentsModule { }
