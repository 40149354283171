import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalProvider } from '../global-provider.component';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Usuario } from '../model/usuario.model';
import { Login } from '../model/login.model';
import { HttpRequestUtils } from '../utils/clases/httpRequestUtil';

@Injectable()
export class LoginService {

    constructor(
        private _globalProvider: GlobalProvider,
        private _httpRequestUtils: HttpRequestUtils
    ) { }

    llamaLogin(uname: string, pwd: string): Observable<Login> {
        let usuario: Usuario = new Usuario;
        usuario.email = uname;
        usuario.password = pwd;
        return this._httpRequestUtils.postRequestLogin(
            this._globalProvider.iniciarSesion,
            usuario
        );
    }
}
