<!-- ============================================================== -->
<!-- Profile - style you can find in header.scss -->
<!-- ============================================================== -->

<div class="far-end">
    <span class="space-reducer"><h3><b>{{userName | titlecase}}</b></h3></span>
    <span class="space-reducer" style="text-align: right;">{{rol}}</span>
    
  </div>
  <button [matMenuTriggerFor]="profile" mat-icon-button>
    <mat-icon class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true">settings </mat-icon>
</button>
<mat-menu #profile="matMenu" class="mymegamenu">

    <button mat-menu-item (click)="openDialog()">
        <mat-icon>store</mat-icon> Cambiar Tienda
    </button>
    <button mat-menu-item (click)="administracion()">
        <mat-icon>account_box</mat-icon> Administración
    </button>
    <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon> Salir
    </button>
</mat-menu>