import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';

export const AppRoutes: Routes = [
    {
        path: '',
        component: FullComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: '/login', pathMatch: 'full' },
            { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule) },
            { path: 'caja', loadChildren: () => import('./caja/caja.module').then(m => m.CajaComponentsModule) },
            { path: 'venta', loadChildren: () => import('./venta/venta.module').then(m => m.VentaComponentsModule) },
            { path: 'empleado', loadChildren: () => import('./empleado/empleado.module').then(m => m.EmpleadoComponentsModule) },
            { path: 'cliente', loadChildren: () => import('./cliente/cliente.module').then(m => m.ClienteModule) },
            { path: 'company', loadChildren: () => import('./company/company.module').then(m => m.CompanyModule) },
            { path: 'inventario', loadChildren: () => import('./inventario/inventario.module').then(m => m.InventarioComponentsModule) },
            { path: 'insumos', loadChildren: () => import('./insumos/insumos.module').then(m => m.InsumosModule) },
            { path: 'reportes', loadChildren: () => import('./reportes/reportes.module').then(m => m.ReportesComponentsModule) },
            { path: 'tiendas', loadChildren: () => import('./tiendas/tiendas.module').then(m => m.TiendasModule) },
            { path: 'administracion', loadChildren: () => import('./administracion/administracion.module').then(m => m.AdministracionModule) },
            { path: 'portal', loadChildren: () => import('./_portal/portal.module').then(m => m.PortalModule) },
        ]
    },
    { path: 'login', component: LoginComponent, }
];
