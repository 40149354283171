import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
  Generated class for the GlobalProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

import * as timezone from 'moment-timezone';

@Injectable()
export class Calendar {
    
   // const timezone = require('moment-timezone');

    //muestra la fecha y hora actual de santiago de chile
    //independiente si es local o en vps
    //formato estandar: 2020-11-09T00:00:00.000Z
    now = () => {
        return timezone.tz( timezone(), "America/Santiago" ).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    }
    
    //muestra la fecha y hora actual de santiago de chile
    //independiente si es local o en vps
    //formato estandar: 2020-11-09T00:00:00.000Z
    date = (date, format = 'YYYY-MM-DDTHH:mm:ss.SSSZ') => {
        return timezone(date).format(format);
    }
    
    //muestra la fecha y hora actual de santiago de chile
    //se debe informar el formato necesario
    //formato estandar: 2020-11-09
    public nowFormat = (format = 'YYYY-MM-DD') => {
        return timezone.tz( timezone(), "America/Santiago" ).format(format);
    }
    
    //devuelve la hora de comienzo y fin de un dia, por defecto el dia actual
    //modo de uso: let [start, end] = this.startEndDate('2020-11-12');
    startEndDate = (date = this.now()) => {
        let start = timezone.tz( date, "America/Santiago" ).format('YYYY-MM-DDT00:00:00.000') + 'Z';
        let end = timezone.tz( date, "America/Santiago" ).format('YYYY-MM-DDT23:59:59.999') + 'Z';
        return [start, end];
    }

    constructor(public http: HttpClient) {
    }

}
