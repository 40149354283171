import { Injectable } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class SnackBarUtils {

    constructor(private _snackBar: MatSnackBar) { }

    getSnackBarError(message: string) {
        return this._snackBar.open(message, 'X', {
            duration: 20000,
            panelClass: ['error-dialog']
        });
    }

    getSnackBarExito(message: string) {
        return this._snackBar.open(message, 'X', {
            duration: 20000,
            panelClass: ['success-dialog']
        })
    }
}