import { Component } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { environment } from '../environments/environment';

const { title } = environment;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  constructor(private titleService:Title) {
    this.titleService.setTitle(title);
  }
  
}
