import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router,  NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';

import { exhaustMap, tap } from 'rxjs/operators';

import { NgxSpinnerService } from 'ngx-spinner';
import { Subject } from 'rxjs';
import { SpinnerService } from '../service/spinner.service';

@Component({
  selector: 'app-spinner',
  template: `<ngx-spinner size="large" type="timer" color="#1e88e5" bdColor="rgba(235, 243, 247, 0.8)"></ngx-spinner>`,
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {

  spinner: Subject<string> = new Subject<string>();

  constructor(
    private router: Router,
    private spinnerOpt: SpinnerService,
    private spinnerService: NgxSpinnerService,
  ) {
    this.subscribeToSpinner();
    this.router.events
    .pipe(
      tap((event) => {
        if (event instanceof NavigationStart) {
          this.startSpinner();
        } else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel ||
          event instanceof NavigationError
        ) {
          this.spinnerService.hide();
        }
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.spinnerService.hide();
  }

  subscribeToSpinner(): void {
    this.spinnerOpt.isSpinnerVisible.subscribe((value: boolean) => {
      if (value) {
        this.startSpinner();
      } else {
        this.spinnerService.hide();
      }
    } );
  }

  startSpinner(): void {
    this.spinner.pipe(
      exhaustMap(() => this.spinnerService.show())
    ).subscribe();
  }

}
