<div class="login-register" style="background-image:url(assets/images/background/login-register.jpg);">
    <div class="login-register-box">
        <mat-card class="m-t-10">
            <mat-card-content>
                <form id="loginform" (ngSubmit)="iniciaSesion()">
                    <div class="text-center">
                        <img alt="homepage" src="assets/images/optagestion.png" style="width: 32px;">
                        <h4 class="m-t-0">Inicio de sesión</h4>
                    </div>
                    <div *ngIf="msg" class="bg-danger p-10 text-white">{{ msg }}</div>
                    <div fxLayout="row wrap">
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field>
                                <input matInput placeholder="Usuario" [formControl]="form.controls['uname']">
                            </mat-form-field>
                            <small *ngIf="form.controls['uname'].hasError('required') && form.controls['uname'].touched"
                                class="text-danger support-text">Usuario es requerido.</small>
                        </div>
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field>
                                <input matInput type="password" placeholder="Password"
                                    [formControl]="form.controls['password']">
                            </mat-form-field>
                            <small
                                *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched"
                                class="text-danger support-text">Password is required.</small>
                        </div>
                        <!-- col half
                <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" class="text-right">
                    <a [routerLink]="['/forgot']" class="link">Recuperar Contraseña</a>
                </div>-->
                        <!-- col full-->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <button mat-raised-button color="primary" form="loginform"
                                class="btn-block btn-lg m-t-20 m-b-20" type="submit"
                                [disabled]="!form.valid">Login</button>
                        </div>
                    </div>
                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>