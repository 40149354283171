import { Component } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';


@Component({
    selector: 'app-dialog-sesion-expirada',
    templateUrl: 'dialog-sesion-expirada.component.html',
    styleUrls: ['dialog-sesion-expirada.component.scss']
})

export class DialogSesionExpiradaComponent {

    constructor(private routes: Router, private dialogRef: MatDialogRef<DialogSesionExpiradaComponent>,
        public dialog: MatDialog) {
        dialogRef.disableClose = true;

    }

    ngOnInit() {
        this.routes.navigate(['/login']);
    }

    onSubmit() {
        localStorage.clear();
        this.dialogRef.close();
    }

}
