export class Tiendas {

    _id:string;
    nombre: string;
    correlativo: number;
    estado: boolean;
    lenteContacto: boolean;
    cierre: {
        monto: number
    }
}