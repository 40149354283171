<form class="basic-form" [formGroup]="form" >
  <div mat-dialog-content>
    <p>Cambiar tienda</p>
    <mat-form-field>
      <mat-select placeholder="Seleccione tienda" formControlName="tienda" id="tienda">
        <mat-option *ngFor="let tienda of lista" [value]="tienda">
          {{ tienda.nombre }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancelar</button>
    <button mat-button (click)="onSubmit()" mat-flat-button color="warn">Cambiar</button>
  </div>
</form>


