import { Injectable } from "@angular/core";


@Injectable()
export class LocalStorageUtils {

    limpiarLocalStorage(){
        localStorage.clear();
    }

    eliminaValorLocalStorage(valores: string[]){
        for (let value in valores){
            localStorage.removeItem(value);
        }
    }

    insertaValorLocalStorage(mapValues: any){
        for (let [key, value] of mapValues) {
            localStorage.setItem(key, value);
        }
    }

    obtenerValorLocalStorage(valor: string){
        return localStorage.getItem(valor);
    }
}