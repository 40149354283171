import { Injectable } from "@angular/core";
import { Constantes } from "./constantesUtil";

@Injectable()
export class PaginacionUtil {

    cambiaIdiomaPaginacion(_intl: any) {
        _intl.itemsPerPageLabel = Constantes.ITEMS_POR_PAGINA;
        _intl.getRangeLabel = this.getRangeLabel.bind(this);
        _intl.firstPageLabel = Constantes.PRIMERA_PAGINA;
        _intl.lastPageLabel = Constantes.ULTIMA_PAGINA;
        _intl.nextPageLabel = Constantes.PAGINA_SIGUIENTE;
        _intl.previousPageLabel = Constantes.PAGINA_ANTERIOR;
    }

    getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0 || pageSize === 0) {
            return '0 de ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} de ${length}`;
        // return this.translate.instant('RANGE_PAGE_LABEL_2', { startIndex: startIndex + 1, endIndex, length });
    }

}