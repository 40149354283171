import { Usuario } from './../../../model/usuario.model';
import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TiendasService } from '../../../service/tiendas.service';
import { Tiendas } from '../../../model/tienda.model';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Login } from '../../../model/login.model';
import { SnackBarUtils } from '../../../utils/clases/snackBarUtils';
import { LocalStorageUtils } from '../../../utils/clases/localStorageUtil';
import { Constantes } from '../../../utils/clases/constantesUtil';

@Component({
  selector: 'app-dialog-cambio-tienda',
  templateUrl: './dialog-cambio-tienda.component.html',
  styleUrls: ['./dialog-cambio-tienda.component.scss'],
  providers: [TiendasService]
})

export class DialogCambioTiendaComponent {

  lista: Array<Tiendas> = [];

  dataSource = new MatTableDataSource<Tiendas>();
  tiendas: Tiendas[];
  usuarios: Usuario[];
  public form: FormGroup;

  constructor(
    private _snackBarUtil: SnackBarUtils,
    private _dialogRef: MatDialogRef<DialogCambioTiendaComponent>,
    private _tiendasService: TiendasService,
    private _fb: FormBuilder,
    private _localStorageUtils: LocalStorageUtils
  ) { }

  ngOnInit() {
    this.formInicial();
    this.listaTiendas();
  }

  formInicial() {
    this.form = this._fb.group(
      {
        tienda: [
          null,
          Validators.compose([Validators.required])
        ]
      }
    );
  }

  listaTiendas() {
    if (localStorage.getItem('rol') == 'ADMINISTRADOR' || localStorage.getItem('rol') == 'BODEGA') {
      return this._tiendasService.getListaTiendas().subscribe(
        data => {
          this.tiendas = data as Tiendas[];
          for (let tienda of this.tiendas) {
            var estado: string = String(tienda.estado);
            if (estado === 'true' && tienda.nombre != localStorage.getItem('nombreTienda')) {
              this.lista.push(tienda);
            }
          }
        }, errorMessage => {
          this._snackBarUtil.getSnackBarError(errorMessage);
        }
      )
    } else {
      return this._tiendasService.getListaTiendasVendedor().subscribe(
        data => {
          this.usuarios = data as Usuario[];
          for (let usuario of this.usuarios) {
            let item = new Tiendas;
            item._id = usuario.tienda;
            item.nombre = usuario.nombreTienda;
            if (String(item.nombre) != String(localStorage.getItem('nombreTienda')).trim()) {
              this.lista.push(item);
            }
          }
        }, errorMessage => {
          this._snackBarUtil.getSnackBarError(errorMessage);
        }
      )
    }
  }

  onSubmit() {
    let tienda = this.form.get(['tienda']).value;
    this._tiendasService.cambioTienda(tienda).subscribe(
      res => {
        let login = res as Login;
        this.localStorageInfo(login);
        this._dialogRef.close();
        window.location.reload();
      }, errorMessage => {
        this._snackBarUtil.getSnackBarError(errorMessage);
      }
    )
  }

  onNoClick(): void {
    this._dialogRef.close();
  }

  localStorageInfo(login: Login) {
    let eliminaItem: string[] = [Constantes.TOKEN, Constantes.TIENDA];
    let insertaItem = new Map(
      [
        [Constantes.TOKEN, login.token],
        [Constantes.TIENDA, login.user.tienda],
        [Constantes.NOMBRE_TIENDA, login.user.nombreTienda]
      ]
    );
    this._localStorageUtils.eliminaValorLocalStorage(eliminaItem);
    this._localStorageUtils.insertaValorLocalStorage(insertaItem);
  }
}
