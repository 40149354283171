import { Injectable } from '@angular/core';
import { GlobalProvider } from '../global-provider.component';
import { Observable } from 'rxjs';
import { Tiendas } from '../model/tienda.model';
import { Login } from '../model/login.model';
import { Mensaje } from '../model-nuevo/mensaje.model';
import { Usuario } from '../model/usuario.model';
import { HttpRequestUtils } from '../utils/clases/httpRequestUtil';

@Injectable({
    providedIn: 'root',
  })
export class TiendasService {

    constructor(
        private _globalProvider: GlobalProvider,
        private _httpRequestUtils: HttpRequestUtils
    ) { }

    getListaTiendas(): Observable<Tiendas[]> {
        return this._httpRequestUtils.getRequest(
            this._globalProvider.getListaTiendas
        );
    }

    getTiendaById(tienda: any): Observable<Tiendas> {
        return this._httpRequestUtils.getRequest(
            this._globalProvider.getTienda + tienda
        );
    }

    getListaTiendasVendedor(): Observable<Usuario[]> {
        return this._httpRequestUtils.getRequest(
            this._globalProvider.getListaTiendasVendedor
        );
    }

    insertaTienda(tienda: Tiendas): Observable<Mensaje> {
        return this._httpRequestUtils.postRequest(
            this._globalProvider.insertaTienda,
            tienda
        );
    }

    actualizaTienda(tienda: Tiendas): Observable<Mensaje> {
        return this._httpRequestUtils.putRequest(
            this._globalProvider.actualizaTienda + tienda._id,
            tienda
        );
    }

    eliminaTienda(tienda: Tiendas): Observable<Mensaje> {
        return this._httpRequestUtils.deleteRequest(
            this._globalProvider.eliminaTienda + tienda._id
        );
    }

    cambioTienda(tienda: Tiendas): Observable<Login> {
        const obj = { token: localStorage.getItem('token') };
        return this._httpRequestUtils.postRequest(
            this._globalProvider.cambioTienda + tienda._id + '/' + tienda.nombre,
            obj
        );
    }
}
